<template>
    <div class="config-page">
        <div class="title">
            自营业务配置
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="openAddDialog(false)">添加自营业务配置</el-button>
        </div>

        <div class="table-container">
            <el-table border :data="configList">
                <el-table-column prop="type" align="center" label="类型">
                </el-table-column>
                <el-table-column align="center" label="营业时间">
                    <template slot-scope="scope">
                        <div v-for="(item, index) in scope.row.time_str" :key="index">
                            {{ item.start_time + '~' + item.end_time}}
                       </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                    <template slot-scope="scope">
                        {{ scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{ scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                     <template slot-scope="scope">
                     <span>
                        <el-button type="text" size="small" style="margin-right: 10px" @click="openAddDialog(true, scope.row)">编辑</el-button>
                    </span>
                    <span>
                         <el-popconfirm
                            confirmButtonText='好的'
                            cancelButtonText='不用了'
                            icon="el-icon-info"
                            iconColor="red"
                            @onConfirm="deleteConfig(scope.row.config_id)"
                            title="确定删除该配置吗？">
                            <el-button slot="reference" type="text" size="small">删除</el-button>
                        </el-popconfirm>
                     </span>
                     </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog :title="`${isEdit ? '编辑' : '新增'}配置`" class="add-dialog" :close-on-click-modal="false"
                   width="500px" :visible.sync="addConfigDialog">
            <el-form :model="addConfigData" ref="addConfigForm" label-width="78px">
                <el-form-item label="业务分类" prop="type"
                :rules="[{ required: true, message: '请选择业务分类', trigger: 'change' }]">
                     <el-select v-model="addConfigData.type" size="medium" placeholder="请选择">
                        <el-option
                        v-for="(item, index) in goodsTypeList"
                        :key="index"
                        :label="item"
                        :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="营业时间">
                    <div v-for="(item, index) in businessTime" :key="index" :style="`margin-top: ${index === 1 ? '12px' : ''}`">
                        <el-time-select class="select-time"
                        v-model="item.start_time"
                        :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '23:30',
                            minTime: index === 0 ? '' : businessTime[0].end_time
                        }"
                        placeholder="选择时间">
                        </el-time-select>
                        ~
                        <el-time-select class="select-time"
                        v-model="item.end_time"
                        :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '24:00',
                            minTime: index === 0 ? businessTime[0].start_time : businessTime[1].start_time
                        }"
                        placeholder="选择时间">
                        </el-time-select>
                        <el-button style="margin-left: 12px;" v-if="businessTime.length < 2" @click="addTime" size="small">添加</el-button>
                        <el-button style="margin-left: 12px;" v-else type="danger" @click="deleteTime(index)" size="small">删除</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addConfigDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureSaveConfig('addConfigForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: 'Config',
    data () {
        return {
            goodsTypeList: [],
            goodsTypeListAll: [],
            // 配置列表
            configList: [],
            businessTime: [{
                start_time: '',
                end_time: ''
            }],

            // 新增/编辑数据
            addConfigData: {
                type: ''
            },
            addConfigDialog: false,
            isEdit: false
        }
    },
    mounted () {
        this.getGoodsTypeList()
        this.getConfigList()
    },
    watch: {
        configList: {
            handler (newValue) {
                const typeList = newValue.map(item => {
                    return item.type
                })

                this.goodsTypeList = this.goodsTypeListAll.filter((item) => {
                    return !typeList.includes(item)
                })
            },
            deep: true
        }
    },
    methods: {
        // 获取商品分类列表
        getGoodsTypeList () {
            this.axios.post('/good/type/list').then((res) => {
                console.log(res)
                this.goodsTypeListAll = res.data
            })
        },

        // 获取配置列表
        getConfigList () {
            this.axios.post('/open/config/list', {
            }).then((res) => {
                this.configList = res.data.map(item => {
                    item.time_str = JSON.parse(item.time_str)
                    return item
                })
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getConfigList()
        },

        /**
         * 打开弹窗
         * @param isEdit 是否为编辑
         * @param configData 为编辑时，配置数据
         */
        openAddDialog (isEdit, configData) {
            this.isEdit = isEdit
            this.addConfigDialog = true
            // this.addConfigData.merchantsType = configData.merchantsType
            if (isEdit) {
                this.addConfigData = {
                    configId: configData.config_id,
                    type: configData.type
                }
                this.businessTime = configData.time_str
            } else {
                this.addConfigData = {
                    type: ''
                }
                this.businessTime = [{
                    start_time: '',
                    end_time: ''
                }]

                this.$nextTick(() => {
                    this.$refs.addConfigForm.clearValidate()
                })
            }

            console.log(configData)
        },

        /**
         * 删除配置
         * @param configId 删除ID
         */
        deleteConfig (configId) {
            this.axios.post('/open/config/delete', {
                config_id: configId
            }).then((res) => {
                if (res) {
                    this.getConfigList()
                    this.$message.success('删除成功')
                }
            })
        },

        addTime () {
            this.businessTime.push({
                start_time: '',
                end_time: ''
            })
        },

        /**
         * 删除时间段
         * @param index 时间段标识
         */
        deleteTime (index) {
            this.businessTime.splice(index, 1)
        },

        /**
         * 保存配置数据
         * @param formName 表单名称
         */
        sureSaveConfig (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let timeTrue = true
                    if (this.businessTime.length === 1) {
                        if (this.businessTime[0].start_time && this.businessTime[0].end_time) {
                            const startTimeStr = '2000-01-01 ' + this.businessTime[0].start_time
                            const endTimeStr = '2000-01-01 ' + this.businessTime[0].end_time
                            const startStamp = new Date(startTimeStr).getTime()
                            const endStamp = new Date(endTimeStr).getTime()

                            if (startStamp >= endStamp) {
                                timeTrue = false
                            }
                        } else {
                            timeTrue = false
                        }
                    }
                    if (this.businessTime.length === 2) {
                        if (this.businessTime[0].start_time && this.businessTime[0].end_time && this.businessTime[1].start_time && this.businessTime[1].end_time) {
                            const startTimeStr1 = '2000-01-01 ' + this.businessTime[0].start_time
                            const endTimeStr1 = '2000-01-01 ' + this.businessTime[0].end_time
                            const startTimeStr2 = '2000-01-01 ' + this.businessTime[1].start_time
                            const endTimeStr2 = '2000-01-01 ' + this.businessTime[1].end_time
                            const startStamp1 = new Date(startTimeStr1).getTime()
                            const endStamp1 = new Date(endTimeStr1).getTime()
                            const startStamp2 = new Date(startTimeStr2).getTime()
                            const endStamp2 = new Date(endTimeStr2).getTime()
                            console.log(startStamp1 >= endStamp1)
                            console.log(endStamp1 >= startStamp2)
                            console.log(startStamp2 >= endStamp2)
                            console.log(endStamp2)

                            if (startStamp1 >= endStamp1 || endStamp1 >= startStamp2 || startStamp2 >= endStamp2) {
                                timeTrue = false
                            }
                        } else {
                            timeTrue = false
                        }
                    }

                    if (!timeTrue) {
                        this.$message.error('营业时间选择有误，请重新选择！')
                        return
                    }

                    const requestUrl = this.isEdit ? '/open/config/update' : '/open/config/add'
                    const { type, configId } = { ...this.addConfigData }
                    this.axios.post(requestUrl, {
                        config_id: configId,
                        type: type,
                        time_str: JSON.stringify(this.businessTime)
                    }).then((res) => {
                        console.log(res)
                        this.addConfigDialog = false
                        this.$message.success(`成功${this.isEdit ? '编辑' : '添加'}配置`)
                        this.getConfigList()
                    }).catch(() => {
                        // console.log(error)
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
.config-page {
    .title {
        padding: 14px;
        // margin-bottom: 18px;
        border-bottom: 1px solid #dddddd;
        font-size: 28px;
    }

    .search-div {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #dddddd;
    }

    .table-container {
        margin: 10px 20px 0;
    }

    .page-pageination {
        padding: 10px 0;
        text-align: right;
    }

    .add-dialog {
        .el-input, .el-select {
            width: 240px;
        }

        .select-time{
            // .el-input, .el-select {
            //     width: 140px;
            // }
            width: 140px;
        }

        .el-dialog__body {
            padding: 10px 20px;
        }
    }
}
</style>
