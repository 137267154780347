<template>
    <div class="configure-page">
        <div class="title">
            <span>首页配置</span>
        </div>
        <div>
            <el-form
                :model="configureData"
                ref="addGoodsForm"
                status-icon
                :rules="rules"
                label-width="100px"
            >
                <!-- <el-form-item label="打印机名称" prop="name">
                    <el-input v-model="configureData.name" size="medium" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="首页弹窗图片" prop="send_price">
                    <el-upload
                        class="avatar-uploader"
                        action="/api/admin/api/v2/update/file"
                        :show-file-list="false"
                        :on-success="
                            (result) => {
                                handleAvatarSuccess(result, 1);
                            }
                        "
                        :before-upload="beforeAvatarUpload"
                    >
                        <img
                            v-if="configureData.home_bounc"
                            :src="configureData.home_bounc"
                            class="avatar"
                        />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="首页轮播图" prop="date">
                    <el-upload
                        action="/api/admin/api/v2/update/file"
                        list-type="picture-card"
                        :file-list="bannerImgList"
                        :on-exceed="onExceed"
                        :limit="5"
                        :before-upload="beforeAvatarUpload"
                        :on-success="
                            (result, file, fileList) => {
                                handleAvatarSuccess(result, 2, fileList);
                            }
                        "
                        :on-remove="handleRemove"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="平台抽成">
                    <el-input-number
                        v-model="configureData.platform_for"
                        :min="0"
                        :max="1000000"
                        size="medium"
                        autocomplete="off"
                        :precision="2"
                        :step="0.1"
                    ></el-input-number>
                    <div class="danwei1">%</div>
                </el-form-item>
            </el-form>

            <div style="padding: 14px 80px">
                <el-button
                    type="primary"
                    size="small"
                    @click="submitPrinter('addGoodsForm')"
                >
                    保 存
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Printer',
    data () {
        return {
            // 验证规则
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入起送价',
                        trigger: 'change'
                    }
                ]
            },

            // 增加/编辑商品业务数据
            configureData: {
                home_bounc: '',
                home_banner: '',
                platform_for: ''
            },
            bannerImgList: [],
            bannerImgListStr: []
        }
    },
    mounted () {
        this.getConfigData()
    },
    methods: {
        processDataLow (param) {
            return parseFloat((param / 100).toFixed(12))
        },
        processDataMax (param) {
            return param * 100
        },
        handleRemove (file, fileList) {
            console.log(file)
            console.log(fileList)
            const urlStrList = fileList.map((item) => {
                return item.response ? item.response.data.url : item.url
            })
            this.bannerImgListStr = this.bannerImgListStr.filter((item) => {
                return urlStrList.includes(item)
            })
            console.log(this.bannerImgListStr)
        },
        onExceed (file, fileList) {
            this.$message.error('轮播图最多上传5张图片，请删除后再上传！')
        },

        // 上传图片成功回调
        handleAvatarSuccess (res, type) {
            if (type === 1) {
                this.configureData.home_bounc = res.data.url
            } else {
                this.bannerImgListStr.push(res.data.url)
                console.log(this.bannerImgListStr)
            }
        },
        // 图片上传前验证，图片格式、大小等
        beforeAvatarUpload (file) {
            console.log(file.type)
            const isJPG =
                file.type === 'image/jpeg' ||
                file.type === 'image/png' ||
                file.type === 'image/gif'
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG/GIF 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!')
            }
            return isJPG && isLt2M
        },

        // 获取打印机配置
        getConfigData () {
            this.axios.post('/config/get').then((res) => {
                this.configureData.platform_for = this.processDataMax(
                    res.data.platform_for
                )
                this.configureData.home_bounc = res.data.home_bounc
                if (res.data.home_banner) {
                    this.bannerImgListStr = res.data.home_banner.split(',')
                    this.bannerImgList = this.bannerImgListStr.map((item) => {
                        return {
                            name: '',
                            url: item
                        }
                    })
                }
            })
        },

        submitPrinter (formName) {
            this.configureData.home_banner = this.bannerImgListStr.join(',')
            if (
                !this.configureData.home_bounc ||
                !this.configureData.home_banner
            ) {
                this.$message.error('弹窗图片/轮播图不能为空！')

                return
            }
            this.$refs[formName].validate((valid) => {
                const data = this.configureData
                data.platform_for = this.processDataLow(data.platform_for)
                if (valid) {
                    this.axios
                        .post('/config/save', this.configureData)
                        .then((res) => {
                            this.getConfigData()
                            this.$forceUpdate()
                            this.$message.success('保存成功')
                        })
                        .catch(() => {})
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
.configure-page {
    .title {
        padding: 14px;
        margin-bottom: 18px;
        border-bottom: 1px solid #dddddd;
        font-size: 28px;
    }
    .danwei1 {
        position: absolute;
        top: 0%;
        left: 100px;
        z-index: 99;
    }
    .el-input,
    .el-input-number {
        width: 154px;
    }

    .note {
        line-height: 20px;
        font-size: 14px;
        color: #d84040;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #c0ccda;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: #fbfdff;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 148px;
        height: 148px;
        line-height: 148px;
        text-align: center;
    }
    .avatar {
        width: 148px;
        height: 148px;
        display: block;
    }
}
</style>
